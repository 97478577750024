<template>
  <div class="animated fadeIn">
    <div id="Dashboard">
      <div style="height: 48px;">
        <b-button
          size="sm"
          variant="primary"
          class="mr-2"
          @click="
            $router.push({
              name: 'Редактирование предмета',
              params: { id: '0' }
            })
          "
        >
          <i class="icon-plus"></i> Добавить
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="
            () => {
              loadData(false);
            }
          "
        >
          <i class="icon-reload"></i> Обновить
        </b-button>
      </div>

      <Grid
        ref="requestsGrid"
        :items="gridData.items"
        :columns="gridData.columns"
        :classes="gridData.classes"
        :actions="gridData.actions"
        :index="gridData.index"
        :filter-key="gridData.searchQuery"
        :defaultSort="gridData.sortBy"
        :defaultSortOrder="-1"
        :isDataLoading="isPageLoading"
      ></Grid>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import API from "../../../api/stalk.net";
import Grid from "../../../components/common/Grid";

export default {
  name: "ItemsList",
  components: {
    Grid
  },
  data() {
    return {
      isPageLoading: false,
      // request: API.private.getItems,
      gridData: {
        items: [],
        columns: [
          { title: "Id", path: "Id" },
          {
            title: "Код",
            path: "Code",
            handler: str => {
              return str.length > 0 ? str : "нет";
            }
          },
          { title: "Название", path: "Title" },
          {
            title: "Тип предмета",
            path: "ItemType",
            handler: str => {
              return API.getItemTypeTitle(str);
            }
          },
          {
            title: "Чей предмет?",
            path: "Owner",
            handler: str => {
              return API.getItemOwnersTitle(str);
            }
          },
          {
            title: "Базовое расположение",
            path: "Place",
            handler: str => {
              return API.getItemPlacesTitle(str);
            }
          },
          {
            title: "Дата создания",
            path: "CreatedAt",
            handler: str => {
              return Vue.filter("formatDate")(str);
            }
          }
        ],
        index: { title: { path: "Title" }, id: { path: "Id" } },
        classes: [
          {
            path: "ItemType",
            equals: API.consts.ItemTypes.Suit,
            type: "table-green"
          },
          {
            path: "ItemType",
            equals: API.consts.ItemTypes.Artefact,
            type: "table-yellow"
          },
          {
            path: "ItemType",
            equals: API.consts.ItemTypes.Other,
            type: "table-blue"
          }
        ],
        actions: [
          {
            name: "item_edit",
            title: "Редактировать",
            disabled: !this.isActionAvailable("item_edit"),
            filter: this.isCanEdit,
            action: this.edit
          }
        ],
        searchQuery: "",
        sortBy: {}
      },
      baseURL: API.baseURL
    };
  },
  methods: {
    loadData(sort = true) {
      let self = this;

      self.isPageLoading = true;
      API.private
        .getItems()
        .then(resp => {
          self.gridData.items = resp.data;
          if (sort) {
            self.gridData.sortby = { title: "Id", path: "Id" };
            self.$refs.requestsGrid.sortBy(self.gridData.sortby);
          }
          self.isPageLoading = false;
        })
        .catch(this.catchError);
    },
    viewUserDataByRequestId(rid) {
      let self = this;

      API.private
        .getUserByRequest(rid)
        .then(resp => {
          self.selectedUser = resp.data.records;
          self.$refs.userInfoModal.show();
        })
        .catch(self.catchError);
    },
    viewMemberDataByRequestId(rid) {
      let self = this;

      API.private
        .getMemberByRequest(rid)
        .then(resp => {
          self.selectedMember = resp.data.records;
          self.$refs.memberInfoModal.show();
        })
        .catch(this.catchError);
    },
    catchError(e) {
      let self = this;

      console.error(e);
      if (
        e.response &&
        e.response.data.records &&
        e.response.data.records.error
      ) {
        console.error(e.response.data.records);
        switch (e.response.data.records.error) {
          case "ERR_BAD_JWT":
          case "ERR_BAD_AUTH":
          case "ERR_NOT_ALLOWED":
            {
              self.$router.replace("/login");
            }
            break;
        }
        self.showError({ message: e.response });
      }
      self.isPageLoading = false;
    },
    isActionAvailable(name) {
      let requests = localStorage.getItem("actions");
      return requests.includes(name);
    },
    isCanEdit() {
      return true;
    },
    edit(item) {
      this.$router.push({
        name: "Редактирование предмета",
        params: { id: item.Id }
      });
    },

    handleEdit() {
      // this.$refs.confirmModal.hide();
      // self.isPageLoading = true;
      // API.private.approveRequest(self.selectedRequest.Id, self.confirmData).then((resp) => {
      //   self.isPageLoading = false;
      //   return self.loadData();
      // }).catch(self.catchError);
    }
  },
  beforeMount: function() {
    this.loadData();
  },
  notifications: {
    showError: {
      title: "Ошибка",
      type: "error"
    }
  }
};
</script>
